import React, { useState, useEffect } from 'react';
import Style from './Navbar.module.scss';
import Toggler from './home/Toggler';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { info } from '../assets/info/Info';

const links = [
  {
    name: 'logo',
    to: '',
    active: 'logo',
    type: 'logo',
  },
  {
    name: 'Home',
    to: '/',
    active: 'home',
  },
  {
    name: 'Chi sono',
    to: '/about',
    active: 'about',
  },
  {
    name: 'Portfolio',
    to: '/portfolio',
    active: 'portfolio',
  },
];

export default function Navbar({ darkMode, handleClick }) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === '/' ? 'home' : location.pathname.slice(1, location.pathname.length),
  );
  const [isFixed, setIsFixed] = useState(false);
  const [scrollTop, setScrollTop] = useState(0); // New state variable to store scroll position

  const logo = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
    `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 620.55 455.8">
      <g id="Livello_2" data-name="Livello 2">
        <g id="Layer_1" data-name="Layer 1">
          <polygon points="25.65 275.62 0 363.62 74.05 363.62 96.66 275.62 25.65 275.62" fill="black" stroke-width="20%"/>
          <polygon points="205.67 275.62 115.82 275.62 92.54 363.62 185.29 363.62 208.83 275.62 205.67 275.62" fill="black" stroke-width="20%"/>
          <polygon points="496.32 275.62 472.15 363.62 548.6 363.62 573.15 275.62 496.32 275.62" fill="black" stroke-width="20%"/>
          <polygon points="227.4 275.62 203.85 363.62 273.87 363.62 290.91 363.62 261.48 275.62 227.4 275.62" fill="black" stroke-width="20%"/>
          <polygon points="75.05 87.68 49.4 175.68 123.61 175.68 146.85 87.68 75.05 87.68" fill="black" stroke-width="20%"/>
          <polygon points="545.27 87.68 520.67 175.68 596 175.68 620.55 87.68 545.27 87.68" fill="black" stroke-width="20%"/>
          <polygon points="325.61 87.68 356.9 175.68 393.15 175.68 415.46 87.68 325.61 87.68" fill="black" stroke-width="20%"/>
          <polygon points="429.18 455.81 342.53 455.29 239.65 162.67 160.48 455.29 66.95 455.29 189.63 0 277.17 0 379.9 289.77 456.55 0 550.04 0 429.18 455.81" fill="black" stroke-width="20%"/>
        </g>
      </g>
    </svg>
    `
  )}`;

  const logo_white = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
    `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 620.55 455.8">
      <g id="Livello_2" data-name="Livello 2">
        <g id="Layer_1" data-name="Layer 1">
          <polygon points="25.65 275.62 0 363.62 74.05 363.62 96.66 275.62 25.65 275.62" fill="white" stroke-width="20%"/>
          <polygon points="205.67 275.62 115.82 275.62 92.54 363.62 185.29 363.62 208.83 275.62 205.67 275.62" fill="white" stroke-width="20%"/>
          <polygon points="496.32 275.62 472.15 363.62 548.6 363.62 573.15 275.62 496.32 275.62" fill="white" stroke-width="20%"/>
          <polygon points="227.4 275.62 203.85 363.62 273.87 363.62 290.91 363.62 261.48 275.62 227.4 275.62" fill="white" stroke-width="20%"/>
          <polygon points="75.05 87.68 49.4 175.68 123.61 175.68 146.85 87.68 75.05 87.68" fill="white" stroke-width="20%"/>
          <polygon points="545.27 87.68 520.67 175.68 596 175.68 620.55 87.68 545.27 87.68" fill="white" stroke-width="20%"/>
          <polygon points="325.61 87.68 356.9 175.68 393.15 175.68 415.46 87.68 325.61 87.68" fill="white" stroke-width="20%"/>
          <polygon points="429.18 455.81 342.53 455.29 239.65 162.67 160.48 455.29 66.95 455.29 189.63 0 277.17 0 379.9 289.77 456.55 0 550.04 0 429.18 455.81" fill="white" stroke-width="20%"/>
        </g>
      </g>
    </svg>

    `
  )}`;

  const getLogoSrc = () => {
    return darkMode ? logo_white : logo;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsFixed(scrollTop > 0);
      setScrollTop(scrollTop); // Update scroll position state
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top when active state changes
  }, [active]);

  return (
    <Box component={'nav'} width={'100%'} className={Style.navbar + ' ' + (isFixed ? Style.fixed : '')}>
      <Box
        component={'ul'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        padding={'10px'}
        sx={{ backgroundColor: darkMode ? 'darkslategray' : 'cornsilk', opacity: '0.80' }}
        gap={{ xs: '2rem', md: '8rem' }}
        fontSize={'1rem'}
        marginBottom={'10px'}
      >
        {links.map((link, index) => (
          <Box
            key={index}
            component={'li'}
            className={link.active === active && !link.type && Style.active}
            sx={{ borderImageSource: info.gradient }}
          >
            {link.type !== 'logo' ? (
              <Link to={link.to} onClick={() => setActive(link.active)} className={Style.link}>
                {!link.type && <p style={{ padding: '0.5rem 0' }}>{link.name}</p>}
                {link.type && <h1>{link.name}</h1>}
              </Link>
            ) : (
              <Link to={link.to} onClick={() => setActive(link.active)} className={Style.link}>
                <img src={getLogoSrc()}  alt="Logo" style={{ width: '40px', height: 'auto' }} />
              </Link>
            )}
          </Box>
        ))}
        <li>
          <Toggler darkMode={darkMode} handleClick={handleClick} />
        </li>
      </Box>
    </Box>
  );  
}
