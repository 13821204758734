import React from 'react';
import Style from './Home.module.scss';
import me from '../../assets/img/nicola_de_rosa.webp';
import classNames from 'classnames';
import EmojiBullet from './EmojiBullet';
import SocialIcon from './SocialIcon';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';
import Timeline from './Timeline';
import { Link } from 'react-scroll';
import Team from './Team';
import { Helmet } from 'react-helmet';

export default function Home({ darkMode }) {
  
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Helmet>
      <title>Nicola De Rosa, Full Stack Developer - Home Page</title>
      <meta name="title" content="Nicola De Rosa, Full Stack Developer - Home Page" />
      <meta name="description" content="Nicola De Rosa - Software Developer Italiano,E-Commerce,Fullstack Developer,Mobile App Developer Android iOS" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://nicdev.it/" />
      <meta property="og:title" content="Nicola De Rosa, Full Stack Developer - Home Page" />
      <meta property="og:description" content="Nicola De Rosa - Software Developer Italiano,E-Commerce,Fullstack Developer,Mobile App Developer Android iOS" />
      <meta property="og:image" content="https://nicdev.it/website.jpg" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://nicdev.it/" />
      <meta property="twitter:title" content="Nicola De Rosa, Full Stack Developer - Home Page" />
      <meta property="twitter:description" content="Nicola De Rosa - Software Developer Italiano,E-Commerce,Fullstack Developer,Mobile App Developer Android iOS" />
      <meta property="twitter:image" content="https://nicdev.it/website.jpg" />
      <title>Nicola De Rosa, Full Stack Developer - Home Page</title>
      </Helmet>
      <Box
        component={'main'}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'center'}
        minHeight={'calc(100vh - 50px)'}
      >
        <Box
          className={classNames(Style.avatar, Style.shadowed)}
          alt={'image of developer'}
          style={{ background: info.gradient, objectFit: 'cover' }}
          component={'img'}
          src={me}
          width={{ xs: '35vh', md: '40vh' }}
          height={{ xs: '35vh', md: '40vh' }}
          borderRadius={'50%'}
          p={'0.75rem'}
          mb={{ xs: '1rem', sm: 0 }}
          mr={{ xs: 0, md: '2rem' }}
        />
        <Box
          style={{
            borderRadius: '1rem',
            background: darkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
            boxShadow: darkMode
              ? '0 0 10px 0 rgba(0, 0, 0, 0.2)'
              : '0 0 10px 0 rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            padding: '1rem 2rem',
            transition: 'all 0.3s ease',
          }}
        >
          <h1>
            Ciao, sono{' '}
            <span
              style={{
                background: info.gradient,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {info.firstName + " "}
            </span>
            <span className={Style.hand}>🤚</span>
          </h1>
          <h2>{info.position}.</h2>
          <Box component={'ul'} p={'0.8rem'}>
            {info.miniBio.map((bio, index) => (
              <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} />
            ))}
          </Box>
          <Box
            display={'flex'}
            gap={'1.5rem'}
            justifyContent={'center'}
            fontSize={{ xs: '2rem', md: '2.5rem' }}
          >
            {info.socials.map((social, index) => (
              <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
            ))}
          </Box>
        </Box>
      </Box>
      <Box 
        marginTop={'-60px'}
        display={'flex'}
        gap={'1.5rem'}
        justifyContent={'center'}>
        <div className={`${Style.bounce} ${Style.arrow}`}>
          <Link to="progressi" smooth={true} duration={500}>
            <i className="fa fa-arrow-down fa-2x"></i>
          </Link>
        </div>
      </Box>
      <Box marginTop={'50px'}>
      <h1 id="progressi">I miei progressi personali</h1>
      <Timeline />
      </Box>
      <Box marginTop={'50px'}>
      <h1 id="team" className={`${Style.team}`}>Incontra il Team</h1>
      <p className={Style.teamText}>
        NICdev è nato da un'idea personale ma ad oggi non sarei nessuno senza i miei compagni di viaggioche hanno reso nicdev più di una singola persona, bensì una "New Ideal Cooperation" basata sull'esperienza acquisita , INSIEME, in questi ultimi anni.
      </p>
      <Team />
      </Box>
    </Box>
  );
}
