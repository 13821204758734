import self from '../img/nicola_de_rosa.webp';
import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'Nicola',
  lastName: 'De Rosa',
  initials: 'NDR',
  position: 'Full Stack Developer',
  selfPortrait: self,
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, 
  baseColor: colors[0],
  miniBio: [
    {
      emoji: '🇮🇹',
      text: 'Vivo a Napoli, Italia',
    },
    {
      emoji: '💻',
      text: 'Full Stack Developer Autonomo',
    },
    {
      emoji: '📧',
      text: 'me@nicdev.it',
    },
  ],
  socials: [
    {
      link: 'https://github.com/KyrosWeb',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://gitlab.com/KyrosWeb',
      icon: 'fa fa-gitlab',
      label: 'gitlab',
    },
    {
      link: 'https://www.linkedin.com/in/nicderosa/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
    {
      link: 'https://www.facebook.com/KyrosWeb',
      icon: 'fa fa-facebook',
      label: 'facebook',
    },
    {
      link: 'mailto:me@nicdev.it',
      icon: 'fa fa-envelope',
      label: 'email',
    },
  ],
  bio: "Ciao, sono Nicola. Ho iniziato a sviluppare all'età di 12 anni da autodidatta, migliorando le mie capacità con corsi e lezioni, che mi hanno aiutato a crescere sempre di più. Attualmente frequento la facoltà di Medicina e Chirurgia alla Federico II. Spero che con i miei studi e la mia determinazione riuscirò a realizzare uno dei miei più grandi sogni: unire medicina e sviluppo e creare qualcosa di nuovo che possa cambiare l'interazione tra questi due mondi.",
  skills: {
    languages: ['C#', 'JavaScript', 'Java', 'PHP', 'Python', 'HTML/CSS'],
    frameworks: [
      'React',
      'Vue',
      'Express',
      'NestJS',
      'Spring Boot',
      'Bootstrap/jQuery',
    ],
    databases: ['MySQl','PostgreSQL'],
    cloudServices: ['AWS S3','Google Analytics'],
    tools: [
      'Git',
      'Postman',
      'Docker',
      'Elasticsearch',
      'npm',
      'yarn',
    ],
  },
  hobbies: [
    {
      label: 'Medicina',
      emoji: '🩺',
    },
    {
      label: 'Palestra',
      emoji: '🏋️',
    },
    {
      label: 'Video Games',
      emoji: '🎮',
    },
    {
      label: 'Anime',
      emoji: '🎞',
    },
    {
      label: 'Viaggiare',
      emoji: '✈️',
    },
  ],
  portfolio: [
    {
      title: 'Dott.ssa Lucia Santoro ',
      live: 'https://www.dottoressaluciasantoro.it/', 
      image: require('../portfolio/dottssasantoro.webp') ,
      description: 'Custom Website',
    },
    {
      title: 'Martina Lupparelli ',
      live: 'https://martinalupparelli.it/', 
      image: require('../portfolio/martinalupparelli.webp') ,
      description: 'Custom Website',
    },
    {
      title: 'Peephole',
      live: 'https://peephole-app.com/', 
      image: require('../portfolio/peephole.webp') ,
      description: 'App',
    },
    {
      title: 'MC Service',
      live: 'https://mcservicesociety.com/', 
      image: require('../portfolio/mcservice.webp') ,
      description: 'Custom Website',
    },
    {
      title: 'Ginseng Academy',
      live: 'https://academy.ginsengeffect.com/', 
      image: require('../portfolio/ge_academy.webp') ,
      description: 'Custom Website',
    },
    {
      title: 'Minispeed',
      live: 'https://www.minispeed.it/', 
      image: require('../portfolio/minispeed.webp') ,
      description: 'Website',
    },
    {
      title: 'WeWorld',
      live: 'https://www.weworld.it/', 
      image: require('../portfolio/weworld.webp') ,
      description: 'Custom Website'
    },
    {
      title: 'Il Crivello',
      live: 'https://www.ilcrivello.it/', 
      image: require('../portfolio/ilcrivello.webp') ,
      description: 'Custom Website'
    },
    {
      title: 'YouKarro',
      live: 'https://youkarro.com/', 
      image: require('../portfolio/youkarro.webp') ,
      description: 'App',
    },
    {
      title: 'Carolina Run',
      live: '', 
      image: require('../portfolio/carolinarun.webp') ,
      description: 'App',
    },
    {
      title: 'GinsengEffect Website',
      live: 'https://www.ginsengeffect.com/', 
      image: require('../portfolio/ginsengeffect.webp') ,
      description: 'Website',
    },
    {
      title: 'Motormania',
      live: 'https://www.motormaniaquad.com/', 
      image: require('../portfolio/mmania.webp') ,
      description: 'Website',
    },
    {
      title: 'Imin',
      live: '', 
      image: require('../portfolio/imin.webp') ,
      description: 'App/Custom Website',
    },
    {
      title: 'TCI',
      live: '', 
      image: require('../portfolio/tci.webp') ,
      description: 'Custom Website'
    },
    {
      title: 'RS Trainer',
      live: 'https://www.rstrainer.it/', 
      image: require('../portfolio/rstrainer.webp') ,
      description: 'Custom Website',
    },
    {
      title: 'Ediltar Progress',
      live: 'https://www.ediltarprogress.it/', 
      image: require('../portfolio/ediltarprogress.webp') ,
      description: 'Custom Website',
    },
    {
      title: 'Sushi Yugo',
      live: 'https://sushiyugo.it/', 
      image: require('../portfolio/yugo.webp') ,
      description: 'Website',
    },
    {
      title: 'Gabriella Wedding',
      live: 'https://www.gabriellawedding.it/', 
      image: require('../portfolio/gabriellawedding.webp') ,
      description: 'Website'
    },
    {
      title: 'GoSpeed',
      live: '', 
      image: require('../portfolio/gospeed.webp') ,
      description: 'App',
    },
    {
      title: 'NapoliNordWeb TV',
      live: 'https://www.napolinordwebtv.it/', 
      image: require('../portfolio/napolinordwebtv.webp') ,
      description: 'Website',
    },
    {
      title: 'Shibuya',
      live: 'https://www.shibuyapozzuoli.it/', 
      image: require('../portfolio/shibuya.webp') ,
      description: 'Website',
    },
    
  ],
};