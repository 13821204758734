import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import filippoSVG from '../../assets/img/team/first.svg'
import nicolaSVG from '../../assets/img/team/second.svg'
import alessandroSVG from '../../assets/img/team/third.svg'

export default function Team() {
    return (
        <div className="py-5 text-center text-info">
            <div className="container">
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 p-4">
                        <img className="img-fluid d-block mb-3 mx-auto rounded-circle" width="200" src={filippoSVG} alt="Filippo"></img>
                        <h4><b>Filippo</b></h4>
                        <p className="mb-3">Apprendista Sviluppatore</p>
                    </div><div className="col-lg-4 col-md-4 p-4">
                        <img className="img-fluid d-block mb-3 mx-auto rounded-circle" width="200" src={nicolaSVG} alt="Nicola"></img>
                        <h4><b>Nicola</b></h4>
                        <p className="mb-3">Sviluppatore Professionista</p>
                    </div>
                    <div className="col-lg-4 col-md-4 p-4">
                        <img className="img-fluid d-block mb-3 mx-auto rounded-circle" src={alessandroSVG} width="200" alt="Alessandro"></img>
                        <h4><b>Alessandro</b></h4>
                        <p className="mb-3">Sviluppatore Professionista</p>
                    </div>
                </div>
            </div>
        </div>
    )
};
