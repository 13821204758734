import React from 'react';
import Style from './About.module.scss';
import Terminal from './Terminal';
import { Box } from '@mui/material';
import { info } from '../../assets/info/Info';
import { Helmet } from 'react-helmet';

export default function About() {
  const firstName = info.firstName.toLowerCase();

  function aboutMeText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}
            {info.lastName.toLowerCase().split(" ").join("")} $
          </span>{' '}
          cat about{firstName}{' '}
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            about{firstName} <span className={Style.green}>(main)</span> ${' '}
          </span>
          {info.bio}
        </p>
      </>
    );
  }

  const skillsSection = (skill) => {
    const keyName = Object.keys(skill)[0];
    const values = skill[keyName];

    return (
      <div key={keyName}>
        <p style={{ color: info.baseColor }}> {keyName}</p>
        <ul className={Style.skills}>
          {values.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </ul>
      </div>
    );
  };

  function skillsText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}
            {info.lastName.toLowerCase().split(" ").join("")} $
          </span>{' '}
          cd skills
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            skills <span className={Style.green}>(main)</span> $
          </span>{' '}
          ls
        </p>
        {Object.keys(info.skills).map((key) => {
          return skillsSection({ [key]: info.skills[key] });
        })}
      </>
    );
  }

  function miscText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>
            {firstName}
            {info.lastName.toLowerCase().split(" ").join("")} $
          </span>{' '}
          cd hobbies/interests
        </p>
        <p>
          <span style={{ color: info.baseColor }}>
            hobbies/interests <span className={Style.green}>(main)</span> $
          </span>{' '}
          ls
        </p>
        <ul>
          {info.hobbies.map((hobby, index) => (
            <li key={index}>
              <Box component={'span'} mr={'1rem'}>
                {hobby.emoji}
              </Box>
              {hobby.label}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
       <Helmet>
        <title>Nicola De Rosa, Full Stack Developer - Chi sono</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nicdev.it/" />
        <meta name="description" content="Nicola De Rosa - Ciao, sono Nicola. Ho iniziato a sviluppare all'età di 12 anni da autodidatta, migliorando le mie capacità con corsi e lezioni, che mi hanno aiutato a crescere sempre di più" />
        <meta property="og:title" content="Nicola De Rosa, Full Stack Developer - Chi sono" />
        <meta property="og:description" content="Nicola De Rosa - Ciao, sono Nicola. Ho iniziato a sviluppare all'età di 12 anni da autodidatta, migliorando le mie capacità con corsi e lezioni, che mi hanno aiutato a crescere sempre di più" />
        <meta property="og:image" content="https://nicdev.it/website.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nicdev.it/" />
        <meta property="twitter:title" content="Nicola De Rosa, Full Stack Developer - Chi sono" />
        <meta property="twitter:description" content="Nicola De Rosa - Ciao, sono Nicola. Ho iniziato a sviluppare all'età di 12 anni da autodidatta, migliorando le mie capacità con corsi e lezioni, che mi hanno aiutato a crescere sempre di più" />
        <meta property="twitter:image" content="https://nicdev.it/website.jpg" />
      </Helmet>
      <Terminal text={aboutMeText()} />
      <Terminal text={skillsText()} />
      <Terminal text={miscText()} />
    </Box>
  );
}
