import React from 'react';
import { Helmet } from 'react-helmet';
import PortfolioBlock from './PortfolioBlock';
import { Box, Grid } from '@mui/material';
import { info } from '../../assets/info/Info';

export default function Portfolio() {
  return (
    <Box>
      <Helmet>
        <title>Nicola De Rosa, Full Stack Developer - Portfolio</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nicdev.it/" />
        <meta name="description" content="Nicola De Rosa - Portfolio dei progetti realizzati comprendenti e-commerce,applicazioni,siti web e tanto altro" />
        <meta property="og:title" content="Nicola De Rosa, Full Stack Developer - Portfolio" />
        <meta property="og:description" content="Nicola De Rosa - Portfolio dei progetti realizzati comprendenti e-commerce,applicazioni,siti web e tanto altro" />
        <meta property="og:image" content="https://nicdev.it/website.jpg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://nicdev.it/" />
        <meta property="twitter:title" content="Nicola De Rosa, Full Stack Developer - Portfolio" />
        <meta property="twitter:description" content="Nicola De Rosa - Portfolio dei progetti realizzati comprendenti e-commerce,applicazioni,siti web e tanto altro" />
        <meta property="twitter:image" content="https://nicdev.it/website.jpg" />
      </Helmet>
      <Grid container display={'flex'} justifyContent={'center'}>
        {info.portfolio.map((project, index) => (
          <Grid item xs={12} md={3} key={index}>
            <PortfolioBlock
              image={project.image}
              live={project.live}
              title={project.title}
              description={project.description}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
